.login_card {
    margin-top: 10%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.password_eye {
    cursor: pointer;
}

.login_error {
    display: block;
    width: 100%;
    text-align: center;
}