body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f5ff;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.center-text {
  width: 100%;
  text-align: center;
}

.overflow-table-wrapper {
  overflow-x: auto;
  width: 100%;
  min-height: 500px;
}

.full-width {
  width: 100%;
}

.error-message {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  align-items: center;
  background: rgba(255, 218, 218, 0.84);
  border-radius: 8px;
}

.column {
  flex-direction: column;
  align-items: flex-start;
}

.success-message {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  align-items: center;
  background: rgba(223, 255, 218, 0.84);
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-cut {
  text-overflow: ellipsis;
}

.app-modal-close {
  cursor: pointer;
}