.dev-badge {
    padding: 8px 4px;
    background: #8f0000;
    width: 300px;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotate(-45deg);

    position: absolute;
    top: 20px;
    left: -100px;
}

@media only screen and (max-width: 800px) {
    .dev-badge {
        width: 200px;
        top: 20px;
        left: -50px;
        font-size: 0.7em;
    }
}